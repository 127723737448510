// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
#root {
    min-width: 320px;
    height: 100%;
}

body,
input,
button {
  overflow: hidden;
  font-family: "Roboto", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}`, "",{"version":3,"sources":["webpack://./src/styled.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;;;IAGI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;;;EAGE,gBAAgB;EAChB,iCAAiC;;EAEjC,mCAAmC;EACnC,kCAAkC;;AAEpC","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\nhtml,\nbody,\n#root {\n    min-width: 320px;\n    height: 100%;\n}\n\nbody,\ninput,\nbutton {\n  overflow: hidden;\n  font-family: \"Roboto\", sans-serif;\n\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
