import styled from "styled-components";
export const Container = styled.main`
    display:flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        152deg,
        #f3f8ff,
        #5e9afb
    );
`;
export const Content = styled.div`
    display: flex;
    width: 370px;
    height: 550px;
    padding: 1rem;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    /* border: solid 1px #dde8fb; */
    /* background-color: #ffffff; */
    img {
        max-width: 200px;
    }
    > form {
        display: flex;
        width: 300px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
`;
export const Input = styled.input`
    outline: none;
    border: solid 1px #e5e9f2;
    width: 100%;
    border-radius: 8px;
    height: 2.5rem;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #575757;
    &:hover{
        border-color:#5e9afb ;
    }
`;
export const BtnLogin = styled.button`
    outline: none;
    border: none;
    width: 100%;
    height: 2.5rem;
    border-radius: 8px;
    color: #f3f8ff;
    background-color: #5e9afb;
    cursor: pointer;
    margin-top: 20px;
    &:disabled {
        opacity: 0.5;
        cursor:no-drop;
        color: #7e8c94; 
        background-color:#eff2f7;
    }
    &:not(:disabled):hover {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.18);
  }
`;