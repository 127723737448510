import { useState } from 'react';
import {
    Container,
    Content,
    Input,
    BtnLogin
} from './styled';
import { toast } from 'react-toastify';
import svg_neuron from '../../assets/SVGs/neuron.svg';

export function Login({ setUserLogged }) {


    const [userLogin, setUserLogin] = useState({
        username: '',
        password: ''
    })

    async function handleSubmit(e) {
        e.preventDefault();
        if (userLogin.username === "admin" &&
            userLogin.password === "neuron@2024"
        ) {
            setUserLogged(true);
        }
        else {
            toast.error(`Nome de Usuário ou Senha incorretos !`,
                { position: "top-center", theme: "colored" }
            );
        }
    };

    return (
        <Container>
            <Content>
                <img src={svg_neuron} alt="" />
                <form>
                    <Input
                        type="text"
                        name='username'
                        id='username'
                        autoComplete='username'
                        placeholder="Nome de Usuário"
                        value={userLogin.username}
                        onChange={(event) => setUserLogin({
                            ...userLogin,
                            username: event.target.value
                        })}
                    />
                    <Input
                        type="password"
                        name='password'
                        id='password'
                        autoComplete='current-password'
                        placeholder="Senha"
                        value={userLogin.password}
                        onChange={(event) => setUserLogin({
                            ...userLogin,
                            password: event.target.value
                        })}
                    />
                    <BtnLogin
                        disabled={
                            !(userLogin.username && userLogin.password)
                        }
                        onClick={(e) => handleSubmit(e)}
                    >
                        {`Entrar`}
                    </BtnLogin>
                </form>
            </Content>
        </Container>
    )
}