import { useState } from 'react';
import {
    Container,
    Content,
    Response
} from './styled';
import { ConnectButton } from "360dialog-connect-button";
import { FaUser } from "react-icons/fa";
import { FaClipboard } from "react-icons/fa";
import svg from '../../assets/SVGs/contact.svg';

export function Onboarding() {

    const [response, setResponse] = useState({
        client: '',
        channels: ''
    })
    const handleCallback = (callbackObject) => {
        /* The callback function returns 
        the client ID as well as all channel IDs, 
        for which you're enabled to fetch the API 
        key via the Partner API */
        console.log("client ID: " + callbackObject.client)
        console.log("channel IDs: " + callbackObject.channels);
        setResponse({
            client: callbackObject.client,
            channels: callbackObject.channels
        })
    };

    return (
        <Container>
            <Content>
                <img
                    src={svg}
                    alt="inProgress"
                />
                {(response.channels && response.client) &&
                    <Response>
                        <div>
                            <span>
                                <FaUser />
                            </span>
                            <p>
                                {response.client}
                            </p>
                        </div>
                        {response?.channels?.slice(",")?.map((item, index) => {
                            return (
                                <div key={`${(index+1)}`}>
                                    <span>
                                        {(index+1)}
                                    </span>
                                    <p>
                                        {item}
                                    </p>
                                </div>
                            )
                        })}
                    </Response>
                }
                <ConnectButton
                    className='btn--connect'
                    partnerId={'ryy5qePA'}
                    callback={handleCallback}
                    label={` Validar ${
                        (response.channels && response.client) && 'novo'
                    } numero`}
                />
            </Content>
        </Container>
    )
}