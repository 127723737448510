import React, { useEffect, useState } from 'react';
import {
  Login,
  Onboarding
} from './pages';

export function App() {

  const [userLogged, setUserLogged] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('client')
      && queryParams.get('channels')
    ) {
      setUserLogged(true);
    }
  }, [])

  return (
    <>
      {(!userLogged)
        ? (<Login
          setUserLogged={setUserLogged}
        />)
        : (<Onboarding />)
      }
    </>
  )
};