import styled from "styled-components";
export const Container = styled.main`
    display:flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const Content = styled.div`
    display: flex;
    width: 320px;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    > img {
        max-width: 300px;
        margin-bottom: 50px;
    }
    .btn--connect{
        outline: none;
        border: none;
        width: 100%;
        height: 2.5rem;
        text-align: center;
        border-radius: 8px;
        color: #f3f8ff;
        background-color: #5e9afb;
        cursor: pointer;
        margin-top: 20px;
        &:hover {
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.18);
        }
    }
`;
export const Response = styled.div`
    display: flex;
    width: 100%;
    max-height: 320px;
    flex-direction: column;
    border-radius: 10px;
    gap: 1rem;
    overflow-y: scroll;

    > div{
        display: flex;
        width: 100%;
        min-height: 2.5rem;
        align-items: center;
        border-radius: 8px;
        background-color: #ffffff;
        border: solid 1px #e5e9f2;
        span{
            display: flex;
            width:40px;
            min-height: 2.5rem;
            justify-content: center;
            align-items: center;
            border-radius: 8px 0 0 8px;
            background-color: #f1f1f1;
            color: #2f2e41;
        }
        p{
            margin-left: 5px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #575757;
        }
    }

`;
export const Input = styled.input`
    outline: none;
    border: solid 1px #e5e9f2;
    width: 100%;
    border-radius: 8px;
    height: 2.5rem;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #575757;
`;